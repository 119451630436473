
import { defineComponent, onMounted } from "vue";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle
} from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "asset-overview",
  components: {

  },
  props: {
    //assetId: String
  },
  methods: {
    /*fixPhotosOrder(start) {
      console.log(start);
      if (start === 0) {
        return this.shortInfo.photos.xl;
      } else {
        const reorderedArray = this.shortInfo.photos.xl.splice(2).concat(this.shortInfo.photos.xl.splice(0,2));
        console.log(reorderedArray);
        return reorderedArray;
      }
    },*/
    loadAJAXData() {
      ApiService.setHeader();
      ApiService.get(
        "https://cmms.onecorp.cloud/api/v2/assets/short/" + this.assetId
      )
        .then(data => {
          //console.log(data);
          //update project tabs table
          //this.title = "Assets details filled with AJAX data";
          this.$nextTick(() => {
            this.shortInfo = data.data.data;
            //console.log(this.shortInfo);
            setCurrentPageTitle(this.shortInfo.title);
            setCurrentPageBreadcrumbs(this.shortInfo.title, [
              "Asset management",
              "Asset inventory"
            ]);
          });
          this.dataLoaded = true;
        })
        .catch(response => {
          //console.log(response);
        });
    }
  },
  created() {
    const route = useRoute();
    this.assetId = route.params.assetId;
    setTimeout(() => {
      this.loadAJAXData();
    }, 100);
  },
  /*setup() {
    onMounted(() => {
      //setCurrentPageToolbarType("assetsList");
    });
  },*/
  data: function() {
    let assetId: any;
    let shortInfo: any;
    const dataLoaded = false;
    return { assetId, shortInfo, dataLoaded };
  }
});
