<template>
  <!--begin::Navbar-->
  <div
    :class="[!dataLoaded && 'overlay overlay-block']"
    class="card mb-5 mb-xxl-8"
  >
    <div class="card-body pt-9 pb-0">
      <div
        class="overlay-layer rounded bg-white bg-opacity-5"
        :class="[dataLoaded && 'd-none']"
      >
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <!--begin::Details-->
      <div
        class="flex-wrap flex-sm-nowrap mb-3"
        :class="{ 'd-flex': dataLoaded, 'd-none': !dataLoaded }"
        v-if="dataLoaded"
      >
        <!--begin: Pic-->
        <div class="me-7 mb-4">
          <div
            class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative ribbon"
          >
            <img
              :src="`${shortInfo.photos[0].sm}`"
              alt=""
              style="object-fit: cover;"
              v-if="shortInfo.photos.length > 0"
            />
            <img
              src="media/misc/pattern-1.jpg"
              alt=""
              style="object-fit: cover;"
              v-if="shortInfo.photos.length == 0"
            />
            <div
              class="position-absolute translate-middle bottom-0 start-100 mt-2 bg-danger rounded-circle border border-4 border-white h-20px w-20px d-none"
            ></div>
            <div :class="`ribbon-label bg-${shortInfo.status.class} mt-15`">
              <span
                  v-if="shortInfo.status.label === 'Online'">In service</span>
              <span
                  v-if="shortInfo.status.label === 'Offline'">Out of service</span>
            </div>
          </div>
        </div>
        <!--end::Pic-->

        <!--begin::Info-->
        <div class="flex-grow-1">
          <!--begin::Title-->
          <div
            class="d-flex justify-content-between align-items-start flex-wrap mb-2"
          >
            <!--begin::User-->
            <div class="d-flex flex-column">
              <!--begin::Name-->
              <div class="d-flex align-items-center mb-2">
                <a
                  href="#"
                  class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                  >{{ shortInfo.title }}</a
                >
                <a href="#">
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <inline-svg src="media/icons/duotone/Design/Verified.svg" />
                  </span>
                </a>
              </div>
              <!--end::Name-->

              <!--begin::Info-->
              <div class="d-flex flex-wrap fw-bold fs-4 mb-4 pe-2">
                <a
                  href="#"
                  class="d-flex align-items-center text-gray-600 text-hover-primary me-5 mb-2"
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg src="media/icons/duotone/Media/Playlist2.svg" />
                  </span>
                  {{ shortInfo.type }}
                </a>
                <a
                  href="#"
                  class="d-flex align-items-center text-gray-600 text-hover-primary me-5 mb-2"
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg
                      src="media/icons/duotone/Tools/Screwdriver.svg"
                    />
                  </span>
                  {{ shortInfo.manufacturer }}
                </a>
                <!--<a
                  href="#"
                  class="d-flex align-items-center text-gray-600 text-hover-primary mb-2"
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg src="media/icons/duotone/Map/Marker1.svg" />
                  </span>
                  {{ shortInfo.location.label }}
                </a>-->
              </div>
              <!--end::Info-->
            </div>
            <!--end::User-->

            <!--begin::Actions-->
            <div class="d-flex my-4 d-none">
              <a
                href="#"
                class="btn btn-sm btn-light me-2"
                id="kt_user_follow_button"
              >
                <span class="svg-icon svg-icon-3 d-none">
                  <inline-svg
                    src="media/icons/duotone/Navigation/Double-check.svg"
                  />
                </span>
                Follow
              </a>

              <a
                href="#"
                class="btn btn-sm btn-primary me-3"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_offer_a_deal"
                >Hire Me</a
              >
            </div>
            <!--end::Actions-->
          </div>
          <!--end::Title-->

          <!--begin::Stats-->
          <div class="d-flex flex-wrap flex-stack">
            <!--begin::Wrapper-->
            <div class="d-flex flex-column flex-grow-1 pe-8">
              <!--begin::Stats-->
              <div class="d-flex flex-wrap">
                <!--begin::Stat-->
                <div
                  class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <span class="svg-icon svg-icon-3 svg-icon-primary me-2">
                      <inline-svg src="media/icons/duotone/Home/Clock.svg" />
                    </span>
                    <div
                      class="fs-2 fw-bolder"
                      data-kt-countup="true"
                      :data-kt-countup-value="shortInfo.workingHours"
                      data-kt-countup-suffix="h"
                    >
                      {{ shortInfo.workingHours }}
                    </div>
                  </div>
                  <!--end::Number-->

                  <!--begin::Label-->
                  <div class="fw-bold fs-6 text-gray-600 ps-8">
                    Working hours
                  </div>
                  <!--end::Label-->
                </div>
                <!--end::Stat-->

                <!--begin::Stat-->
                <div
                  class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <span class="svg-icon svg-icon-3 svg-icon-primary me-2">
                      <inline-svg
                        src="media/icons/duotone/Interface/Calendar.svg"
                      />
                    </span>
                    <div
                      class="fs-2 fw-bolder"
                    >
                      {{ shortInfo.age }} year<span v-if="shortInfo.age > 1">s</span>
                    </div>
                  </div>
                  <!--end::Number-->

                  <!--begin::Label-->
                  <div class="fw-bold fs-6 text-gray-600 ps-8">Age</div>
                  <!--end::Label-->
                </div>
                <!--end::Stat-->

                <!--begin::Stat-->
                <div
                  class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <span class="svg-icon svg-icon-3 svg-icon-primary me-2">
                      <inline-svg src="media/icons/duotone/Tools/Tools.svg" />
                    </span>
                    <div
                      class="fs-2 fw-bolder"
                      data-kt-countup="true"
                      data-kt-countup-value="60"
                      data-kt-countup-prefix="%"
                    >
                      January 2023
                    </div>
                  </div>
                  <!--end::Number-->

                  <!--begin::Label-->
                  <div class="fw-bold fs-6 text-gray-600 ps-8">
                    Next maintenance
                  </div>
                  <!--end::Label-->
                </div>
                <!--end::Stat-->
              </div>
              <!--end::Stats-->
            </div>
            <!--end::Wrapper-->

            <!--begin::Progress-->
            <div
              class="d-flex align-items-center w-200px w-sm-300px flex-column mt-3"
            >
              <div class="d-flex justify-content-between w-100 mt-auto mb-2">
                <span class="fw-bold fs-6 text-gray-400"
                  >Equipment Health Score</span
                >
                <span class="fw-bolder fs-6"
                  >{{ shortInfo.healthScore.value }}%</span
                >
              </div>

              <div class="h-5px mx-3 w-100 bg-light mb-3">
                <div
                  :class="`bg-${shortInfo.healthScore.class} rounded h-5px`"
                  role="progressbar"
                  :style="`width: ${shortInfo.healthScore.value}%;`"
                  :aria-valuenow="`${shortInfo.healthScore.value}`"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <!--end::Progress-->
          </div>
          <!--end::Stats-->
        </div>
        <!--end::Info-->
      </div>
      <!--end::Details-->

      <div
        class="flex-column flex-md-row rounded border p-10 mb-8"
        :class="{ 'd-flex': dataLoaded, 'd-none': !dataLoaded }"
        v-if="dataLoaded"
      >
        <ul
          class="nav nav-tabs nav-pills flex-row border-0 flex-md-column me-5 mb-3 mb-md-0 fs-6 min-w-300px"
        >
          <li class="nav-item me-0 mb-md-2">
            <a
              class="fm_tab_el nav-link active btn btn-flex btn-active-light-primary"
              data-bs-toggle="tab"
              role="tab"
              href="#asset_overview"
            >
              <span class="svg-icon svg-icon-2">
                <inline-svg src="media/icons/duotone/General/Clipboard.svg" />
              </span>
              <span class="d-flex flex-column align-items-start">
                <span class="fs-4 fw-bolder">Overview</span>
              </span>
            </a>
          </li>
          <li class="nav-item me-0 mb-md-2">
            <a
              class="fm_tab_el nav-link btn btn-flex btn-active-light-primary"
              data-bs-toggle="tab"
              role="tab"
              href="#asset_tsi"
            >
              <span class="svg-icon svg-icon-2">
                <inline-svg src="media/icons/duotone/Text/Align-left.svg" />
              </span>
              <span class="d-flex flex-column align-items-start">
                <span class="fs-4 fw-bolder">Technical information</span>
              </span>
            </a>
          </li>
          <li class="nav-item me-0 mb-md-2">
            <a
              class="fm_tab_el nav-link btn btn-flex btn-active-light-primary"
              data-bs-toggle="tab"
              role="tab"
              href="#asset_manual"
            >
              <span class="svg-icon svg-icon-2">
                <inline-svg src="media/icons/duotone/Files/File.svg" />
              </span>
              <span class="d-flex flex-column align-items-start">
                <span class="fs-4 fw-bolder">Manuals</span>
              </span>
            </a>
          </li>
          <li class="nav-item ">
            <a
              class="fm_tab_el nav-link btn btn-flex btn-active-light-primary"
              data-bs-toggle="tab"
              role="tab"
              href="#asset_mr_old"
            >
              <span class="svg-icon svg-icon-2">
                <inline-svg src="media/icons/duotone/Home/Clock.svg" />
              </span>
              <span class="d-flex flex-column align-items-start">
                <span class="fs-4 fw-bolder">Maintenance records</span>
              </span>
            </a>
          </li>
          <li class="nav-item ">
            <a
              class="fm_tab_el nav-link btn btn-flex btn-active-light-primary"
              data-bs-toggle="tab"
              role="tab"
              href="#asset_photos"
            >
              <span class="svg-icon svg-icon-2">
                <inline-svg src="media/icons/duotone/Files/Pictures1.svg" />
              </span>
              <span class="d-flex flex-column align-items-start">
                <span class="fs-4 fw-bolder">Photos</span>
              </span>
            </a>
          </li>
          <li class="nav-item ">
            <a
              class="fm_tab_el nav-link btn btn-flex btn-active-light-primary"
              data-bs-toggle="tab"
              role="tab"
              href="#asset_activity"
            >
              <span class="svg-icon svg-icon-2">
                <inline-svg src="media/icons/duotone/Code/Time-schedule.svg" />
              </span>
              <span class="d-flex flex-column align-items-start">
                <span class="fs-4 fw-bolder">Activity</span>
              </span>
            </a>
          </li>
        </ul>
        <div
          class="tab-content w-100 shadow-sm px-5 py-5"
          id="assetTabsContent"
          style="border-radius: 10px;"
        >
          <div
            class="tab-pane fade active show"
            id="asset_overview"
            role="tabpanel"
          >
            <div class="row mb-4">
              <div class="col-6">
                <div class="fs-7 text-muted fw-bold">Title</div>
                <div class="fs-5 fw-bolder">{{ shortInfo.title }}</div>
              </div>
              <div class="col-3">
                <div class="fs-7 text-muted fw-bold">S/N</div>
                <div class="fs-5 fw-bolder">{{ shortInfo.sn }}</div>
              </div>
              <div class="col-3">
                <div class="fs-7 text-muted fw-bold">ID</div>
                <div class="fs-5 fw-bolder">{{ shortInfo.eq_id }}</div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-3">
                <div class="fs-7 text-muted fw-bold">Category</div>
                <div class="fs-5 fw-bolder">{{ shortInfo.category_list }}</div>
              </div>
              <!--<div class="col-3">
                <div class="fs-7 text-muted fw-bold">Position</div>
                <div class="fs-5 fw-bolder">
                  <span
                    :class="`badge badge-light-${shortInfo.position.class}`"
                  >
                    <span class="svg-icon svg-icon-2">
                      <inline-svg
                        :src="
                          `media/icons/duotone/${shortInfo.position.icon}.svg`
                        "
                      />
                    </span>
                    {{ shortInfo.position.label }}
                  </span>
                </div>
              </div>-->
              <div class="col-3">
                <div class="fs-7 text-muted fw-bold">Status</div>
                <div class="fs-5 fw-bolder">
                  <span :class="`badge badge-light-${shortInfo.status.class}`">
                    <span class="svg-icon svg-icon-2">
                      <inline-svg
                        :src="
                          `media/icons/duotone/${shortInfo.status.icon}.svg`
                        "
                      />
                    </span>

                    <span
                        v-if="shortInfo.status.label === 'Online'">In service</span>
                    <span
                        v-if="shortInfo.status.label === 'Offline'">Out of service</span>
                  </span>
                </div>
              </div>
              <!--<div class="col-3">
                <div class="fs-7 text-muted fw-bold">Offshore</div>
                <div class="fs-4 fw-bolder" v-if="shortInfo.offshore == 1">
                  <span class="badge badge-light-info">Currently offshore</span>
                </div>
                <div class="fs-4 fw-bolder" v-if="shortInfo.offshore != 1">
                  <span class="badge badge-light-dark">Onshore</span>
                </div>
              </div>-->
              <div class="col-3">
                <div class="fs-7 text-muted fw-bold">Manufacturer</div>
                <div class="fs-5 fw-bolder">{{ shortInfo.manufacturer }}</div>
              </div>
              <div class="col-3">
                <div class="fs-7 text-muted fw-bold">Owner</div>
                <div class="fs-5 fw-bolder">{{ shortInfo.owner }}</div>
              </div>
              <!--<div class="col-3">
                <div class="fs-7 text-muted fw-bold">Client</div>
                <div class="fs-5 fw-bolder">Socar Cape JV </div>
              </div>
              <div class="col-3">
                <div class="fs-7 text-muted fw-bold">Location</div>
                <div class="fs-5 fw-bolder">
                  <span
                    :class="`badge badge-light-${shortInfo.location.class}`"
                    >{{ shortInfo.location.label }}</span
                  >
                </div>
              </div>-->
            </div>
            <div class="row mb-4">
              <div class="col-3">
                <div class="fs-7 text-muted fw-bold">Purchase date</div>
                <div class="fs-5 fw-bolder">
                  {{ shortInfo.purchaseDate.display }}
                </div>
              </div>
              <div class="col-3">
                <div class="fs-7 text-muted fw-bold">Manufacture date</div>
                <div class="fs-5 fw-bolder">
                  {{ shortInfo.manufactureDate.display }}
                </div>
              </div>
              <div class="col-3">
                <div class="fs-7 text-muted fw-bold">Put in service</div>
                <div class="fs-5 fw-bolder">
                  {{ shortInfo.installDate.display }}
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <!--<div class="col-6">
                <div class="fs-7 text-muted fw-bold">Dimensions</div>
                <div class="fs-5 fw-bolder">{{ shortInfo.dimensions }}</div>
              </div>-->
              <div class="col-6">
                <div class="fs-7 text-muted fw-bold">Comments</div>
                <div class="fs-5 fw-bolder">{{ shortInfo.comments }}</div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="asset_tsi" role="tabpanel">
            <div class="row">
              <template
                v-for="(tsi, index) in shortInfo.spec_details"
                :key="index"
              >
                <div class="col-6 mb-5" v-if="tsi.label !== 'EQ type'">
                  <div class="fs-7 text-muted fw-bold">{{ tsi.label }}</div>
                  <div class="fs-5 fw-bolder">{{ tsi.value }}</div>
                </div>
              </template>
            </div>
          </div>
          <div class="tab-pane fade" id="asset_manual" role="tabpanel">
            <template v-for="(manual, index) in shortInfo.manuals" :key="index">
              <div class="mb-7 d-flex align-items-center">
                <div class="symbol symbol-50px me-5">
                  <span class="bg-light-danger symbol-label">
                    <span class="svg-icon-danger svg-icon svg-icon-2x">
                      <inline-svg
                        src="media/icons/duotone/Files/Compiled-file.svg"
                      />
                    </span>
                  </span>
                </div>
                <div class="d-flex flex-column">
                  <a
                    :href="`${manual.link}`"
                    target="_blank"
                    class="text-dark text-hover-primary fs-6 fw-bolder"
                    >{{ manual.name.split(".")[0].replaceAll("_", " ") }}</a
                  >
                  <span class="text-muted fw-bold">{{
                    manual.name.split(".")[1].toUpperCase()
                  }}</span>
                </div>
              </div>
            </template>
          </div>
          <div class="tab-pane fade" id="asset_mr_old" role="tabpanel">
            <div class="row">
              <ul
                  class="nav nav-tabs nav-pills flex-row border-0 me-5 mb-3 mb-md-0 fs-6"
              >
                <li class="nav-item me-0 mb-md-2">
                  <a
                      class="fm_tab_el nav-link active btn btn-flex btn-active-light-primary"
                      data-bs-toggle="tab"
                      role="tab"
                      href="#asset_mr_reports"
                  >
                    <span class="d-flex flex-column align-items-start">
                <span class="fs-4 fw-bolder">Maintenance reports</span>
              </span>
                  </a>
                </li>
                <li class="nav-item me-0 mb-md-2">
                  <a
                      class="fm_tab_el nav-link btn btn-flex btn-active-light-primary"
                      data-bs-toggle="tab"
                      role="tab"
                      href="#asset_mr_certificates"
                  >
                    <span class="d-flex flex-column align-items-start">
                <span class="fs-4 fw-bolder">Certificates</span>
              </span>
                  </a>
                </li>
                <li class="nav-item me-0 mb-md-2">
                  <a
                      class="fm_tab_el nav-link btn btn-flex btn-active-light-primary"
                      data-bs-toggle="tab"
                      role="tab"
                      href="#asset_mr_prejob_reports"
                  >
                    <span class="d-flex flex-column align-items-start">
                <span class="fs-4 fw-bolder">Pre-mob reports</span>
              </span>
                  </a>
                </li>
                <li class="nav-item me-0 mb-md-2">
                  <a
                      class="fm_tab_el nav-link btn btn-flex btn-active-light-primary"
                      data-bs-toggle="tab"
                      role="tab"
                      href="#asset_mr_fault_reports"
                  >
                    <span class="d-flex flex-column align-items-start">
                <span class="fs-4 fw-bolder">Fault reports</span>
              </span>
                  </a>
                </li>
              </ul>
              <div
                  class="tab-content w-100 shadow-sm px-5 py-5"
                  id="assetMRTabsContent"
                  style="border-radius: 10px;"
              >
                <div
                    class="tab-pane fade active show"
                    id="asset_mr_reports"
                    role="tabpanel"
                >
                  <div class="mb-7 d-flex align-items-center">
                    <div class="symbol symbol-50px me-5">
                    <span class="bg-light-danger symbol-label">
                      <span class="svg-icon-danger svg-icon svg-icon-2x">
                        <inline-svg
                            src="media/icons/duotone/Files/Compiled-file.svg"
                        />
                      </span>
                    </span>
                    </div>
                    <div class="d-flex flex-column">
                      <a
                          href="https://myassets.az/files/pmr.pdf"
                          target="_blank"
                          class="text-dark text-hover-primary fs-6 fw-bolder"
                      >PMR SG-EQP-061</a
                      >
                      <span class="text-muted fw-bold">PDF</span>
                    </div>
                  </div>
                </div>
                <div
                    class="tab-pane fade"
                    id="asset_mr_certificates"
                    role="tabpanel"
                >
                    <div class="mb-7 d-flex align-items-center">
                      <div class="symbol symbol-50px me-5">
                    <span class="bg-light-danger symbol-label">
                      <span class="svg-icon-danger svg-icon svg-icon-2x">
                        <inline-svg
                            src="media/icons/duotone/Files/Compiled-file.svg"
                        />
                      </span>
                    </span>
                      </div>
                      <div class="d-flex flex-column">
                        <a
                            href="https://myassets.az/files/calibration_cert.pdf"
                            target="_blank"
                            class="text-dark text-hover-primary fs-6 fw-bolder"
                        >Calibration certificate SG-1-006</a
                        >
                        <span class="text-muted fw-bold">PDF</span>
                      </div>
                    </div>
                </div>
                <div
                    class="tab-pane fade"
                    id="asset_mr_fault_reports"
                    role="tabpanel"
                >
                  <template
                      v-for="(mr, index) in shortInfo.maintenance_reports"
                      :key="index"
                  >
                    <div class="mb-7 d-flex align-items-center">
                      <div class="symbol symbol-50px me-5">
                    <span class="bg-light-info symbol-label">
                      <span class="svg-icon-info svg-icon svg-icon-2x">
                        <inline-svg
                            src="media/icons/duotone/Files/Compiled-file.svg"
                        />
                      </span>
                    </span>
                      </div>
                      <div class="d-flex flex-column">
                        <a
                            :href="`${mr.link}`"
                            target="_blank"
                            class="text-dark text-hover-primary fs-6 fw-bolder"
                        >{{ mr.name.split(".")[0].replaceAll("_", " ") }}</a
                        >
                        <span class="text-muted fw-bold">{{
                            mr.name.split(".")[1].toUpperCase()
                          }}</span>
                      </div>
                    </div>
                  </template>
                </div>
                <div
                    class="tab-pane fade"
                    id="asset_mr_prejob_reports"
                    role="tabpanel"
                >

                  <div class="mb-7 d-flex align-items-center">
                    <div class="symbol symbol-50px me-5">
                    <span class="bg-light-danger symbol-label">
                      <span class="svg-icon-danger svg-icon svg-icon-2x">
                        <inline-svg
                            src="media/icons/duotone/Files/Compiled-file.svg"
                        />
                      </span>
                    </span>
                    </div>
                    <div class="d-flex flex-column">
                      <a
                          href="https://myassets.az/files/prejob.pdf"
                          target="_blank"
                          class="text-dark text-hover-primary fs-6 fw-bolder"
                      >Pre-mob report SG-EQP-200</a
                      >
                      <span class="text-muted fw-bold">PDF</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--<div class="tab-pane fade" id="asset_parts" role="tabpanel">
            <PartsList
              widget-classes="card-xxl-stretch mb-5"
              :asset="`${shortInfo.id}`"
            ></PartsList>
          </div>-->
          <div class="tab-pane fade" id="asset_photos" role="tabpanel">
            <div class="row">
              <div v-viewer="{ url: 'data-src' }" class="images">
                <template
                  v-for="(photo, index) in shortInfo.photos"
                  :key="index"
                >
                  <div
                    class="col-3 mb-5 galleryPhotoThumb symbol symbol-100px symbol-lg-160px symbol-fixed"
                  >
                    <img
                      :src="photo.sm"
                      class="image"
                      :data-src="photo.md"
                      :alt="shortInfo.title"
                    />
                  </div>
                </template>
              </div>

              <!--<template v-for="(photo, index) in shortInfo.photos.sm" :key="index">
                  <el-image
                      class="col-2 mb-5 assetPhotoThumb symbol symbol-100px symbol-lg-160px symbol-fixed"
                      fit="cover"
                      :initial-index="index"
                      :src="photo"
                      :preview-src-list="shortInfo.photos.xl">
                  </el-image>
              </template>-->
            </div>
          </div>
          <div class="tab-pane fade" id="asset_activity" role="tabpanel">
            <ul
                class="nav nav-tabs nav-pills flex-row border-0 me-5 mb-3 mb-md-0 fs-6"
            >
              <li class="nav-item me-0 mb-md-2">
                <a
                    class="fm_tab_el nav-link active btn btn-flex btn-active-light-primary"
                    data-bs-toggle="tab"
                    role="tab"
                    href="#asset_activity_maintenance"
                >
                    <span class="d-flex flex-column align-items-start">
                <span class="fs-4 fw-bolder">Maintenance</span>
              </span>
                </a>
              </li>
              <li class="nav-item me-0 mb-md-2">
                <a
                    class="fm_tab_el nav-link btn btn-flex btn-active-light-primary"
                    data-bs-toggle="tab"
                    role="tab"
                    href="#asset_activity_certificates"
                >
                    <span class="d-flex flex-column align-items-start">
                <span class="fs-4 fw-bolder">Certificates</span>
              </span>
                </a>
              </li>
            </ul>
            <div
                class="tab-content w-100 shadow-sm px-5 py-5"
                id="assetActivityTabsContent"
                style="border-radius: 10px;"
            >
              <div
                  class="tab-pane fade active show"
                  id="asset_activity_maintenance"
                  role="tabpanel"
              >

                <table class="table table-rounded table-striped border gs-1 gs-sm-7">
                  <thead>
                  <tr class="fw-bolder fs-6 text-gray-800  text-center">
                    <th rowspan="2"></th>
                    <th colspan="5">Levels</th>
                  </tr>
                  <tr class="fw-bolder fs-6 text-gray-800 text-center">
                    <th>A</th>
                    <th>B</th>
                    <th>C</th>
                    <th>D</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="fw-bold">
                    <td>Next</td>
                    <td>01 Oct 2022</td>
                    <td>14 Nov 2022</td>
                    <td>19 Dec 2022 <br> or 1000 hours</td>
                    <td>05 Feb 2023 <br> or 2500 hours</td>
                  </tr>
                  <tr>
                    <td>10 Sep 2022</td>
                    <td></td>
                    <td></td>
                    <td><a href="https://myassets.az/files/pmr.pdf"
                           target="_blank">View report</a></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>05 Jul 2022</td>
                    <td><a href="https://myassets.az/files/pmr.pdf"
                           target="_blank">View report</a></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>28 Mar 2022</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><a href="https://myassets.az/files/pmr.pdf"
                           target="_blank">View report</a></td>
                  </tr>
                  <tr>
                    <td>09 Feb 2022</td>
                    <td></td>
                    <td><a href="https://myassets.az/files/pmr.pdf"
                           target="_blank">View report</a></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>06 Jan 2022</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><a href="https://myassets.az/files/pmr.pdf"
                           target="_blank">View report</a></td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div
                  class="tab-pane fade"
                  id="asset_activity_certificates"
                  role="tabpanel"
              >

                <table class="table table-rounded table-striped border gs-1 gs-sm-7">
                  <thead>
                  <tr class="fw-bolder fs-6 text-gray-800  text-center">
                    <th rowspan="2"></th>
                    <th colspan="5">Type</th>
                  </tr>
                  <tr class="fw-bolder fs-6 text-gray-800 text-center">
                    <th>Hoses</th>
                    <th>Gauges</th>
                    <th>PSV</th>
                    <th>PRV</th>
                    <th>ATEX</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="fw-bold">
                    <td>Next</td>
                    <td>01 Oct 2022</td>
                    <td>14 Nov 2022</td>
                    <td>19 Dec 2022 <br> or 1000 hours</td>
                    <td>05 Feb 2023 <br> or 1100 hours</td>
                    <td>10 Jul 2023</td>
                  </tr>
                  <tr>
                    <td>10 Sep 2022</td>
                    <td></td>
                    <td></td>
                    <td><a href="https://myassets.az/files/calibration_cert.pdf"
                           target="_blank">View certificate</a></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>05 Jul 2022</td>
                    <td><a href="https://myassets.az/files/calibration_cert.pdf"
                           target="_blank">View certificate</a></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>28 Mar 2022</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><a href="https://myassets.az/files/calibration_cert.pdf"
                           target="_blank">View certificate</a></td>
                  </tr>
                  <tr>
                    <td>09 Feb 2022</td>
                    <td></td>
                    <td><a href="https://myassets.az/files/calibration_cert.pdf"
                           target="_blank">View certificate</a></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>06 Jan 2022</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><a href="https://myassets.az/files/calibration_cert.pdf"
                           target="_blank">View certificate</a></td>
                    <td></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Navbar-->
</template>
<script lang="ts">
import { defineComponent, onMounted } from "vue";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle
} from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "asset-overview",
  components: {

  },
  props: {
    //assetId: String
  },
  methods: {
    /*fixPhotosOrder(start) {
      console.log(start);
      if (start === 0) {
        return this.shortInfo.photos.xl;
      } else {
        const reorderedArray = this.shortInfo.photos.xl.splice(2).concat(this.shortInfo.photos.xl.splice(0,2));
        console.log(reorderedArray);
        return reorderedArray;
      }
    },*/
    loadAJAXData() {
      ApiService.setHeader();
      ApiService.get(
        "https://cmms.onecorp.cloud/api/v2/assets/short/" + this.assetId
      )
        .then(data => {
          //console.log(data);
          //update project tabs table
          //this.title = "Assets details filled with AJAX data";
          this.$nextTick(() => {
            this.shortInfo = data.data.data;
            //console.log(this.shortInfo);
            setCurrentPageTitle(this.shortInfo.title);
            setCurrentPageBreadcrumbs(this.shortInfo.title, [
              "Asset management",
              "Asset inventory"
            ]);
          });
          this.dataLoaded = true;
        })
        .catch(response => {
          //console.log(response);
        });
    }
  },
  created() {
    const route = useRoute();
    this.assetId = route.params.assetId;
    setTimeout(() => {
      this.loadAJAXData();
    }, 100);
  },
  /*setup() {
    onMounted(() => {
      //setCurrentPageToolbarType("assetsList");
    });
  },*/
  data: function() {
    let assetId: any;
    let shortInfo: any;
    const dataLoaded = false;
    return { assetId, shortInfo, dataLoaded };
  }
});
</script>
